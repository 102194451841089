.header {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid $information-darker;
    padding: 0 20px;
    position: relative;
    z-index: 20;

    &--admin {
        border-bottom-color: $alt;
    }
}

.header__logo {
    height: 60px;
    margin: 10px 0;
}

.header__tabs {
    margin-left: auto;
}

.header__tab-icon {
    margin-right: 10px;
    vertical-align: middle;
}

.bp5-tab {
    color: $mutedText;
}

.bp5-tab-list > *:not(:last-child) {
    margin-right: 30px;
}
